
@charset "utf-8";

// Brand colors
$dark-blue: #023466;
$light-blue: #6ba5d0;
$green: #92c850;
$brand-yellow: #d4af37;
$red: #c30c3e;
$gold: #f79646;

// Update Bulma's global variables
$family-sans-serif: 'Roboto', sans-serif;
$primary: $dark-blue;
$info: $light-blue;
$link: $dark-blue;
$gold-invert: findColorInvert($gold);
$excel-green: #1d6f42;
$excel-green-invert: findColorInvert($excel-green);
$custom-colors: ("draft": ($gold, $gold-invert), "excel": ($excel-green, $excel-green-invert));
$widescreen-enabled: false;
$fullhd-enabled: false;
$menu-item-hover-color: #fff;
$menu-item-hover-background-color: $link;

// Import Bulma sass
@import '../node_modules/bulma/sass/utilities/_all.sass';
@import '../node_modules/bulma/sass/base/_all.sass';
@import '../node_modules/bulma/sass/elements/button.sass';
@import '../node_modules/bulma/sass/elements/container.sass';
@import '../node_modules/bulma/sass/elements/content.sass';
@import '../node_modules/bulma/sass/elements/icon.sass';
@import '../node_modules/bulma/sass/elements/notification.sass';
@import '../node_modules/bulma/sass/elements/other.sass';
@import '../node_modules/bulma/sass/elements/title.sass';
@import '../node_modules/bulma/sass/form/_all.sass';
@import '../node_modules/bulma/sass/layout/_all.sass';
@import '../node_modules/bulma/sass/components/breadcrumb.sass';
@import '../node_modules/bulma/sass/components/menu.sass';
@import '../node_modules/bulma/sass/components/navbar.sass';

// Customize elements
.breadcrumb {
  box-shadow: 0 2px 0 0 $grey-lighter; }

.is-clickable {
  cursor: pointer; }

.ReactTable.-striped .rt-tr.-odd {
  background: rgba($light-blue, .1); }

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba($light-blue, .2); }

// .ReactTable .rt-thead .rt-th
//   background: $dark-blue
//   color: white

.ReactTable .rt-thead .rt-th.-sort-asc {
  box-shadow: inset 0 3px 0 0 $dark-blue; }

.ReactTable .rt-thead .rt-th.-sort-desc {
  box-shadow: inset 0 -3px 0 0 $dark-blue; }

.ReactTable .rt-tbody .rt-td {
  align-self: center; }

.ReactTable.-striped .rt-tr.error {
  background: rgba($red, .6); }

.notification {
  position: sticky;
  z-index: 20;
  top: 1rem;
  left: 1rem;
  right: 1rem; }

.table-links {
  .submit {
    color: $dark-blue; }
  .submit:hover {
    color: darken($dark-blue, 30%);
    text-decoration: underline; }
  .draft {
    color: $gold; }
  .draft:hover {
    color: darken($gold, 30%);
    text-decoration: underline; } }

.react-datepicker-wrapper {
  width: 100%; }
.react-datepicker__input-container {
  width: 100%; }

// Fix IE and Edge incompatibility with :focus-within in Bulma 0.7.5
// Navbar
a.navbar-item,
.navbar-link {
  cursor: pointer;
  &:focus,
  &:hover,
  &.is-active {
    background-color: $navbar-item-hover-background-color;
    color: $navbar-item-hover-color; } }

@include from($navbar-breakpoint) {
  .navbar {
    min-height: $navbar-height;
    &.is-transparent {
      .navbar-item.has-dropdown {
        &.is-active,
        &.is-hoverable:focus,
        &.is-hoverable:hover {
          .navbar-link {
            background-color: transparent !important; } } } } }
  .navbar-item {
    &.is-active,
    &.is-hoverable:focus,
    &.is-hoverable:hover {
      .navbar-dropdown {
        display: block;
        .navbar.is-spaced &,
        &.is-boxed {
          opacity: 1;
          pointer-events: auto;
          transform: translateY(0); } } } } }

.pad-top {
  padding-top: 2rem;
  &.buttons {
    justify-content: space-evenly; } }

.loading-overlay {
  position: fixed;
  z-index: 10;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($grey-lighter, .7);
  cursor: wait; }
