@charset "utf-8";

@import '../../App.sass';

.validation-list {
  padding-left: 25px; }

.validation-list > li {
  display: block; }

.validation-list > li:before {
  font-family: 'Glyphicons Halflings';
  font-size: 12px;
  float: left;
  margin-top: 4px;
  margin-left: -17px; }

.validation-list > li.error {
  color: $red; }

.validation-list > li.error:before {
  content: "\00D7";
  color: $red; }

.validation-list > li.success {
  color: $green; }

.validation-list > li.success:before {
  content: "\2713";
  color: $green; }

.validation-list > li.idle {
  color: $grey; }

.validation-list > li.idle:before {
  content: "\2022";
  color: $grey; }

.validation-list > li.load {
  color: $light-blue; }

.validation-list > li.load:before {
  content: "\231b";
  color: $light-blue; }

.validation-list > li.info {
  color: $light-blue; }

.validation-list > li.info:before {
  content: "\2139";
  color: $light-blue; }
